export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
  // Проверяем, начинается ли номер с кода Таджикистана (+992)
  if (cleaned.startsWith('992')) {
      const match = cleaned.match(/^992(\d{2})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
          return `+992 ${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
      }
  }
  
  // Стандартный формат (если номер не относится к Таджикистану)
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
      return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
  }
  
  return null;
};