import styles from "./PayBlock.module.scss";
import { CopyButton } from "UI/CopyButton/CopyButton";
import CopySVG from "svg/icons/CopySVG";
import { useTranslation } from "react-i18next";
import { formatCardNumber } from "utils/formatCardNumber";
import { formatValue } from "utils/formatValue";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import useUrlData from "store/DataStore";

const bankNames = [
  { name: "Альфабанк", code: "alfa" },
  { name: "Сбербанк", code: "sber" },
  { name: "Открытие" },
  { name: "Тинькофф", code: "tink" },
  { name: "Райффайзен", code: "raif" },
  { name: "ВТБ", code: "vtb" },
  { name: "Газпромбанк" },
  { name: "Совкомбанк" , code: 'sovkom'},
  { name: "МТС-Банк" , code: 'mts'},
  { name: "Росбанк" },
  { name: "Россельхозбанк" },
  { name: "Уралсиб" },
  { name: "Промсвязьбанк" },
  { name: "ОТП", code: 'otp'},
  { name: "Яндекс Банк", code: 'yand'},
  { name: "Душанбе Сити (Таджикистан)", code: 'dush'},
  { name: "Эсхата Банк (Таджикистан)", code: 'eshata'},
  { name: "Спитамен Банк (Таджикистан)", code: 'spita'},
  { name: "Международный банк Таджикистана (IBT)", code: 'mbt'}
];

const PayBlock = () => {
  const allData = useUrlData((state) => state.data);
  const { t } = useTranslation();

  const bank = bankNames.find(el => el.name === allData.bank_name);
  const bankCode = bank && bank.code ? bank.code.toUpperCase() : '';

  return (
    <div className={styles.payBlock}>
      <div className={styles.head}>
        <div className={styles.cont}>
          <div className={styles.left}>
            <div className={styles.logo}>
            <img
              src={`/banks/${bankCode}.svg`}
              alt=""
            />
            </div>

            <div className={styles.name}>
              <span>{t("Банк получаетеля")}</span>
              <p>{t(`${allData.bank_name}`)}</p>
            </div>
          </div>

          <div>
            {allData.phone_number !== null && (
              <div className={styles.sbp}>
                <img src={"/banks/SBP.svg"} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.value}>
          <div className={styles.text}>
            <span>{t("Сумма для перевода")}</span>
            <p>{formatValue(allData.amount)} ₽</p>
          </div>
          <CopyButton
            message={t("Скопирована сумма перевода")}
            value={allData.amount}
          >
            <CopySVG />
          </CopyButton>
        </div>

        <div className={styles.value}>
        <div className={styles.text}>
          {allData.transfer_method === 'ACCOUNT_NUMBER' ? (
            <>
              <span>{t("Номер счета получателя")}</span>
              <p>{allData.account_number ? allData.account_number : 0}</p>
            </>
          ) : allData.phone_number !== null ? (
            <>
              <span>{t("Номер телефона для перевода")}</span>
              <p>{formatPhoneNumber(allData.phone_number)}</p>
            </>
          ) : allData.bank_card_number !== null ? (
            <>
              <span>{t("Номер карты получателя")}</span>
              <p>{formatCardNumber(allData.bank_card_number)}</p>
            </>
          ) : (
            <></>
          )}
        </div>
          <CopyButton
            message={ 
              allData.phone_number !== null ? t("Скопирован номер телефона") :
              allData.bank_card_number !== null && allData.transfer_method !== 'ACCOUNT_NUMBER' ? t("Скопирован номер карты") : 
              allData.transfer_method === 'ACCOUNT_NUMBER' ? t("Скопирован номер счета") : ''
            }
            value={ 
              allData.phone_number !== null ? allData.phone_number : 
              allData.bank_card_number !== null && allData.transfer_method !== 'ACCOUNT_NUMBER' ? allData.bank_card_number : 
              allData.transfer_method === 'ACCOUNT_NUMBER' ? allData.account_number : ''
            }
          >
          <CopySVG />
          </CopyButton>
        </div>

        {allData.transfer_method === 'ACCOUNT_NUMBER' ? 
        <>
        <div className={styles.value}>
          <div className={styles.text}>
            <span>{t("БИК")}</span>
            <p>{allData.bic}</p>
          </div>
          <CopyButton
            message={t("Скопирован БИК")}
            value={allData.bic}
          >
            <CopySVG />
          </CopyButton>
        </div>
        <div className={styles.value}>
          <div className={styles.text}>
            <span>{t("Назначение перевода")}</span>
            <p>Перевод</p>
          </div>
          <CopyButton
            message={t("Скопировано назначение перевода")}
            value={t("Перевод")}
          >
            <CopySVG />
          </CopyButton>
        </div>
      </>
        
        :
        <></>
        }
      </div>
    </div>
  );
};

export default PayBlock;
